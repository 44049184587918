

h1
  font-size: 1.7em

header p
  margin-top: .3em
  margin-bottom: 1.5em



.archive li
  padding: .3em 0

.archive a
  font-size: 1.1em

.archive time
  display: inline-block
  font-size: 1.05em
  width: 6em
  margin: 0 .25em

.year
  min-width: 7em
  display: inline-block
  vertical-align: top

.embed
  position: relative
  padding-bottom: 56.25%
  margin: 1.5em 0

.embed iframe
  position: absolute
  width: 100%
  height: 100%

@media (min-width: 70em)
  .large img, .large.embed
    max-width: 140%
    margin-right: -20%
    margin-left: -20%

  .embed.large
    padding-bottom: 78.75%
